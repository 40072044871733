svg {
  display: block;
}

.n1 {
  width: 111px;
  height: 26px;
}

.n2 {
  width: 130px;
  height: 42px;
}

.n3 {
  width: 37px;
  height: 37px;
}

.n4 {
  width: 68px;
  height: 68px;
}

.n5 {
  width: 22px;
  height: 22px;
}

.n6 {
  width: 20px;
  height: 16px;
}

.n7 {
  width: 400px;
  height: 500px;
}

.n8 {
  width: 32px;
  height: 32px;
}

.n9 {
  width: 48px;
  height: 48px;
}

.n10 {
  width: 260px;
  height: 260px;
}

.n10 {
  width: 12px;
  height: 16px;
}
