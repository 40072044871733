footer {
  padding: 64px 0 50px 0;
  background-color: var(--blue-700);
  color: white;
}

span.divider {
  display: block;
  background-color: white;
  margin: 34px 0;
  padding: 1px;
  border-radius: 2px;
}

article {
  margin: 57px 0 24px 0;
}

section > nav > ul {
  list-style: none;
}

section > nav > ul > li {
  margin-bottom: 12px
}

span.vertical-divider {
  height: 42px;
  background-color: white;
  padding: 1px;
  border-radius: 2px;
}

div.tech-info-container {
  height: 42px;
  display: flex;
  align-items: center;
  gap: 32px;
}

.fix-on-bottom {
  margin-bottom: 0;
}

.call-click-anchor {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.tech-copy-priv{
  justify-content: center;
  display: grid;
  gap: 20px;
}

@media screen and (max-width: 362px){
  div.tech-info-container {
    margin-top: 12px;
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  span.vertical-divider {
    display: none;
  }
}
