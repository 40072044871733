div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    animation: fadeIn 0.8s forwards;
    &.spinner {
      background-color: var(--neutral-darkest);
      display: none;
    }
    &.block-ui {
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
    &.fade-out {
      animation: fadeOut 0.6s forwards;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:first-child {
        z-index: 3002;
        width: 80px;
        animation: fadeAnimation 2s infinite;
      }
      &:nth-child(2) {
        width: 80px;
        transform: translate(-50%, -62%);
        animation: fadeIn 1s linear, moveUp 2s infinite;
      }
      &:nth-child(3) {
        width: 80px;
        transform: translate(-50%, -38%);
        animation: fadeIn 1s linear, moveDown 2s infinite;
      }
    }
  }
  
  @keyframes fadeAnimation {
    0% {
      opacity: 0;
      display: none;
    }
    25% {
      opacity: 0;
      display: none;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  
  @keyframes moveUp {
    0% {
      transform: translate(-50%, -20%);
    }
    50% {
      transform: translate(-50%, -60%);
    }
    100% {
      transform: translate(-50%, -20%);
    }
  }
  
  @keyframes moveDown {
    0% {
      transform: translate(-50%, -80%);
    }
    50% {
      transform: translate(-50%, -40%);
    }
    100% {
      transform: translate(-50%, -80%);
    }
  }
  