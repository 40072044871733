:host(.crediblue-mobile-menu ){
  overflow: hidden;
  width: 100dvw;
  height: 100dvh;
  background-color: #00A9E0;
  position: fixed;
  left: 100%;
  top: 88px;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
  .crediblue-mobile-menu-wrapper {
    overflow: auto;
    height: 100dvh;
    padding-bottom: 5rem;

    .mobile-menu-nav {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      row-gap: 1.2rem;
      width: 100%;

      a.flat {
        height: 40px;
        max-width: 224px;
        width: 100%;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
      }

      .nav-bar-text {
        text-decoration: none;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #FEFEFE;
      }

      .mobile-menu-nav-sub {
        display: flex;
        flex-direction: column;

        button.target {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #FFFFFF;
          border: none;
          background-color: transparent;
          padding: 0;
          margin: 0;

          .icon {
            transition: all 0.5s ease-in-out;

            svg {
              stroke: #FEFEFE;
              color: #FEFEFE;
              fill: #FEFEFE;

            }
          }



        }

        button.target[expanded-area="true"]>.icon {
          transform: rotateZ(180deg);
        }
        button.target[active="true"]{
          span, .nav-bar-text  {
            color: #731FB5;
          }

          .icon svg {
            stroke: #731FB5;
            color: #731FB5;
            fill: #731FB5;
          }
        }

      }

      .mobile-sub-menu {
        display: flex;
        flex-direction: column;
        row-gap: 0.9rem;
        padding: 18px;
      }
    }
  }

}

:host(.crediblue-mobile-menu)[active="true"] {
  left: 0;
  transition: all 0.5s ease-in-out;
}
