.fixed-top {
  z-index: 9;
}

.nav-bar {
  height: 88px;
  color: #FFF;
  background: #FEFEFE;
}

.navbar-nav {
  padding: 0px;

  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1160px) {
    font-size: 10px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 8px;
  }

  @media screen and (max-width: 995px) {
    display: none;
    font-size: 8px;
  }
}

.nav-icon {
  margin-top: 5px;
}

.dropdown-menu {
  padding: 0;

  .dropdown-item {
    width: 100%;
    padding: 16px 3;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: var(--grey-500);
    transition: 0.2s;

    &:hover {
      background-color: var(--purple-400);
      color: white;
      transition: 0.2s;
    }
  }

  span {
    width: 90%;
    height: 1px;
    margin: 0 auto;
    display: block;
    background-color: var(--grey-300);
  }
}

.nav-bar-text {
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: var(--grey-500);

  &:hover {
    color: var(--blue-700)
  }
}

.block-click {
  pointer-events: none;
}

.nav-bar-button-border {
  border: 2px solid var(--purple-400);
  border-radius: 10px;
}

.nav-bar-text-button:hover {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: #FFFFFF;
}

.nav-bar-button {
  margin-left: 20px;
}

.nav-bar-mobile-button {
  display: flex;
  width: 70%;
  height: 100%;
  margin-bottom: 10px;
}

.active-link {
  color: var(--purple-400);
}

.navbar-alignment {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
}


.mobile-menu-wrapper {
  @media screen and (min-width: 996px) {
    display: none;
  }


}

.burger-menu-svg {
  background-color: white;
  border-width: 0;
  scale: 80%;
}

.transparency {
  transform: translateY(-25px) !important;
}

.solutions {
  transform: translateY(-25px) !important;
}
